// extracted by mini-css-extract-plugin
export var achievement = "services-module--achievement--PQHy2";
export var achievementsTitle = "services-module--achievementsTitle--DLgqc";
export var achievemetsWrapper = "services-module--achievemetsWrapper--6AdRp";
export var banner = "services-module--banner--YgzBX";
export var bannerContent = "services-module--bannerContent--hqwXQ";
export var bannerImageWrapper = "services-module--banner-image-wrapper--QpN0-";
export var bannerWrapper = "services-module--bannerWrapper--yvQbs";
export var mark = "services-module--mark--TZrQp";
export var section = "services-module--section--Kdyj4";
export var serviceTitle = "services-module--serviceTitle--BlpdE";
export var strokeWrapper = "services-module--strokeWrapper--RXk8W";
export var wrapper = "services-module--wrapper--qTABC";