import React from "react";
import type { HeadFC } from "gatsby";
import HeroSection from "@/components/home/HeroSection";
import CaseStudy from "@/components/home/CaseStudy";
import ServicesPanel from "@/components/home/ServicesPanel";
import Transform from "@/components/home/Transform";
import Layout from "@/components/Layout";
import ContactForm from "@/components/ContactForm";
import thumbnailImage from "@/images/thumbnail.jpg";
import { GTM } from "@/components/GTM";

const IndexPage = () => {
  return (
    <Layout hero={<HeroSection />}>
      <main>
        <ServicesPanel />
        <CaseStudy />
        <Transform />
        <ContactForm />
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <GTM />
    <title>Home | Multiply</title>
    <meta
      name="description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta
      name="og:description"
      content="Multiply's platform gives you the power to provide automated advice, across a customer's entire life. Join the leading financial institutions improving engagement, retention, and conversions with the next generation of financial advice."
    ></meta>
    <meta name="image" content={thumbnailImage}></meta>
    <meta name="og:image" content={thumbnailImage}></meta>
    <link rel="canonical" href="https://www.multiply.ai/" />
    <link rel="canonical" href="https://blog.multiply.ai/" />
  </>
);
