// extracted by mini-css-extract-plugin
export var bigTitle = "casestudy-module--bigTitle--JSgwb";
export var cs = "casestudy-module--cs--zIBe0";
export var csContent = "casestudy-module--csContent--Euitg";
export var csContentWrapper = "casestudy-module--csContentWrapper--zDqIo";
export var csImg = "casestudy-module--csImg--x75mp";
export var csImgMobile = "casestudy-module--csImgMobile--Daknh";
export var csLogo = "casestudy-module--csLogo--ibbKC";
export var csWrapper = "casestudy-module--csWrapper--kvew5";
export var header = "casestudy-module--header--m-O0a";
export var personImg = "casestudy-module--personImg--DLES+";
export var personName = "casestudy-module--personName--D44Wz";
export var personRole = "casestudy-module--personRole--ov3J0";
export var psersonInfo = "casestudy-module--psersonInfo--2v4mU";
export var smallTitle = "casestudy-module--smallTitle--bCyG2";
export var styledLine = "casestudy-module--styledLine--GdVwE";
export var testimony = "casestudy-module--testimony--WpvAG";