// extracted by mini-css-extract-plugin
export var carIconWrapper = "transform-module--carIconWrapper--9TITy";
export var card = "transform-module--card--R5h7B";
export var cardFeature = "transform-module--cardFeature--f4l2r";
export var cardIcon = "transform-module--cardIcon--lfDLP";
export var cardNumber = "transform-module--cardNumber--QXyCj";
export var cardTitle = "transform-module--cardTitle--QIOIS";
export var cardfeatures = "transform-module--cardfeatures--uqECr";
export var cards = "transform-module--cards--RLjNi";
export var curveText = "transform-module--curveText--k4u01";
export var curveWrapper = "transform-module--curveWrapper--bQsk7";
export var header = "transform-module--header--u+eoO";
export var iso = "transform-module--iso--WmlGf";
export var isoDesc = "transform-module--isoDesc--eIr+V";
export var isoImgWrapper = "transform-module--isoImgWrapper--UemlQ";
export var isoTitle = "transform-module--isoTitle--2P7k0";
export var mark = "transform-module--mark--AVgoG";
export var process = "transform-module--process--9DEAm";
export var title = "transform-module--title--QUMUu";
export var transform = "transform-module--transform--4QLvn";
export var transformWrapper = "transform-module--transformWrapper--O0tE8";