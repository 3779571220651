// extracted by mini-css-extract-plugin
export var bottomText = "herosection-module--bottom-text--VIB5q";
export var buttonWrapper = "herosection-module--button-wrapper--6+f0i";
export var callToActionBtn = "herosection-module--call-to-action-btn--3E1jt";
export var content = "herosection-module--content--CqCKg";
export var contentWrapper = "herosection-module--content-wrapper--SDtpJ";
export var img = "herosection-module--img--p91UY";
export var imgWrapper = "herosection-module--img-wrapper--Wd6sp";
export var secondaryActionBtn = "herosection-module--secondary-action-btn--wK08v";
export var subtitle = "herosection-module--subtitle--nUpCz";
export var textWrapper = "herosection-module--text-wrapper--FXRuv";
export var title = "herosection-module--title--FS53o";
export var wrapper = "herosection-module--wrapper--7VJdV";